import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ProjectBoxLoad() {
  return (
    <div className="project-box-load">
      <div className="project-box-load-image"></div>
      <div className="project-box-load-data">
        <span className="title"></span>
        <div className="label"></div>
        <div className="button border">
          Explorar Proyecto
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="sm" />
        </div>
      </div>
    </div>
  );
}
export default ProjectBoxLoad;
