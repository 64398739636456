import { t } from "i18next";
import { Helmet } from "react-helmet";
import { websiteName } from "../../config/webConfig";
import profile from "../../assets/images/profile.png";

function About() {
  const skills = [
    {
      title: "HTML",
      path: "html",
      show: true,
    },
    {
      title: "CSS",
      path: "css",
      show: true,
    },
    {
      title: "JavaScript",
      path: "javascript",
      show: true,
    },
    {
      title: "TypeScript",
      path: "typescript",
      show: false,
    },
    {
      title: "Python",
      path: "python",
      show: true,
    },
    {
      title: "Kotlin",
      path: "kotlin",
      show: false,
    },
    {
      title: "PHP",
      path: "php_elephant",
      show: true,
    },
    {
      title: "Java",
      path: "java",
      show: true,
    },
    {
      title: "React",
      path: "react",
      show: true,
    },
    {
      title: "Sass",
      path: "sass",
      show: true,
    },
    {
      title: "Firebase",
      path: "firebase",
      show: true,
    },
  ];
  const languages = [
    {
      title: "Español",
      show: true,
    },
    {
      title: "English",
      show: true,
    },
    {
      title: "Catalán",
      show: true,
    },
    {
      title: "Portugués",
      show: true,
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          {t("title.about")} - {websiteName}
        </title>
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="row-caption">
            <h1>Sobre Mí</h1>
            <span className="line"></span>
            <p></p>
          </div>
          <div className="grid about">
            <div className="about-photo">
              <figure>
                <img
                  src={profile}
                  alt="Fotografía de perfil de Daniel Vargas."
                />
              </figure>
            </div>
            <div className="about-details">
              <h2>Daniel Vargas</h2>
              <h3>Desarrollador Web Full Stack</h3>
              <p>
                ¡Hola! Soy <b>Daniel Vargas</b>, un desarrollador web full stack
                con experiencia en la creación de soluciones digitales
                personalizadas para mejorar la presencia en línea de empresas y
                emprendedores.
              </p>
              <p>
                Mi enfoque es construir sitios y aplicaciones web funcionales,
                atractivos y centrados en la experiencia del usuario, combinando
                mis habilidades tanto en desarrollo
                <b> frontend </b>
                como en
                <b> backend</b>. Mi objetivo es crear productos digitales que no
                solo se vean bien, sino que también cumplan con los más altos
                estándares de rendimiento y escalabilidad. Trabajo con
                tecnologías modernas como <b>React</b>, <b>Firebase</b> y
                plataformas populares como
                <b> WordPress</b>, <b>Shopify</b> y <b>Google Sites </b>
                para ofrecer soluciones que se adapten a las necesidades y
                presupuestos de cada cliente.
              </p>
              <p>
                Además de mi experiencia en desarrollo, también soy estudiante
                de <b>Ingeniería en Sistemas y Computación</b>, lo cual me
                permite actualizarme constantemente en las últimas tendencias y
                tecnologías del sector. A lo largo de mi carrera, he trabajado
                en proyectos de diversa complejidad, desde páginas de aterrizaje
                hasta aplicaciones web interactivas, y disfruto tanto del
                desafío técnico como de la creatividad en cada proyecto.
              </p>
            </div>
          </div>
          <hr />
          <div className="block">
            <h2>Habilidades</h2>
            <div className="row-wrap">
              {skills.map(
                (skill, index) =>
                  skill.show && (
                    <div className="item" key={index}>
                      <img
                        src={`./icons/${skill.path}.svg`}
                        alt={skill.title}
                        width={16}
                      />
                      {skill.title}
                    </div>
                  )
              )}
            </div>
          </div>
          <div className="block hidden">
            <h2>Idiomas</h2>
            <div className="row-wrap">
              {languages.map(
                (language, index) =>
                  language.show && (
                    <div className="item" key={index}>
                      {language.title}
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default About;
