import { Helmet } from "react-helmet";
import { websiteName } from "../../config/webConfig";
// import { useNavigate } from "react-router-dom";

function Home() {
  // const Navigate = useNavigate();

  // const actionLink = () => {
  //   Navigate("/contratar");
  // };

  return (
    <>
      <Helmet>
        <title>{websiteName}</title>
      </Helmet>

      <section className="banner">
        <div className="container">
          <div className="banner-box">
            <span className="title">
              Hago que tu negocio brille en línea con sitios web personalizados
            </span>
            <span className="subtitle">
              Utilizando las tecnologías más recientes para dar vida a tus
              ideas. Siempre aprendiendo, siempre creciendo, para ofrecerte lo
              mejor en desarrollo web.
            </span>
            <div className="banner-action">
              <button
                type="button"
                className="button border"
                // onClick={() => actionLink()}
                onClick={() => window.open("https://mail.google.com/mail/?fs=1&tf=cm&source=mailto&to=hello@danielvargas.dev", "_blank")}
              >
                Contratar
              </button>
              {/* <button
                type="button"
                className="button border ghost"
                onClick={() =>
                  window.open(
                    "https://calendar.app.google/bWrCGNaaGqdrajzaA",
                    "_blank"
                  )
                }
              >
                Reservar cita
              </button> */}
            </div>
          </div>
        </div>
      </section>

      {/* <section className="section">
        <div className="container">
          <div className="row-title">
            <h1>Services</h1>
          </div>
          <div className="row-content">
            <div className="grid">
              {services.length === 0 ? (
                <>
                  <ServicesBoxLoad />
                  <ServicesBoxLoad />
                  <ServicesBoxLoad />
                  <ServicesBoxLoad />
                </>
              ) : (
                services
                  .filter((service) => service.show_service)
                  .slice(-4)
                  .map((service) => (
                    <ServiceBox
                      key={service.id}
                      image={service.image}
                      title={service.title}
                      path={service.path}
                      show_service={service.show_service}
                    />
                  ))
              )}
            </div>
          </div>
          <div className="row-action">
            <Link to="/services" className="button primary-button">
              View All Services
            </Link>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <hr />
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row-title">
            <h1>Projects</h1>
          </div>
          <div className="row-content">
            <div className="grid">
              {projects.length === 0 ? (
                <>
                  <ProjectBoxLoad />
                  <ProjectBoxLoad />
                  <ProjectBoxLoad />
                  <ProjectBoxLoad />
                </>
              ) : (
                projects
                  .sort((a, b) => a.id - b.id)
                  .filter((project) => project.show_item)
                  .slice(-4)
                  .map((project) => (
                    <ProjectBox
                      key={project.id}
                      url={project.url}
                      image={project.image}
                      title={project.title}
                      label={project.label}
                      show_item={project.show_item}
                    />
                  ))
              )}
            </div>
          </div>
          <div className="row-action">
            <Link to="/projects" className="button primary-button">
              View All Projects
            </Link>
          </div>
        </div>
      </section> */}
    </>
  );
}
export default Home;
