import { Link } from "react-router-dom";

function ServiceItem(props) {
  return (
    <>
      {props.show && (
        <div className="service-item">
          <div className="service-item-image">
            <img src={props.image} alt={props.title} />
          </div>
          <div className="service-item-data">
            <h2>{props.title}</h2>
            <p>{props.desc}</p>
            {false && (
              <Link to={`/services/${props.path}`} className="button border ghost">
                Más Información
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  );
}
export default ServiceItem;
