import { useParams } from "react-router-dom";
import { useServices } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import { Helmet } from "react-helmet";
import { websiteName } from "../../config/webConfig";

function ServicePage() {
  const { path } = useParams();
  const services = useServices(db);
  const serviceSelected = services.find((service) => service.path === path);

  return (
    <>
      {serviceSelected ? (
        <>
          <Helmet>
            <title>
              {serviceSelected.title} - {websiteName}
            </title>
          </Helmet>
          <section className="section">
            <div className="container">
              <div className="banner-header">
                <img src={serviceSelected.image} alt="" />
              </div>
              <hr />
              <div className="service-page">
                <div className="column">
                  <img
                    src={serviceSelected.image}
                    alt={serviceSelected.title}
                  />
                </div>
                <div className="column">
                  <h1>{serviceSelected.title}</h1>
                  <p>{serviceSelected.desc}</p>
                  <a
                    href={`https://mail.google.com/mail/?fs=1&tf=cm&source=mailto&to=hello@danielvargas.dev`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button ghost-button"
                  >
                    Contratar servicio
                  </a>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <section className="section"></section>
      )}
    </>
  );
}
export default ServicePage;
