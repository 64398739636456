import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function NavMenu(props) {
  const { t } = useTranslation();
  return (
    <nav className="nav-menu">
      <ul className="nav-list">
        <li className="nav-item " onClick={props.click}>
          <Link
            to="/servicios"
            className="nav-link"
            aria-label={t("label.services")}
          >
            {t("title.services")}
          </Link>
        </li>
        <li className="nav-item" onClick={props.click}>
          <Link
            to="/precios"
            className="nav-link"
            aria-label={t("label.pricing")}
          >
            {t("title.pricing")}
          </Link>
        </li>
        <li className="nav-item" onClick={props.click}>
          <Link
            to="/proyectos"
            className="nav-link"
            aria-label={t("label.projects")}
          >
            {t("title.projects")}
          </Link>
        </li>
        <li className="nav-item" onClick={props.click}>
          <Link
            to="/sobre-mi"
            className="nav-link"
            aria-label={t("label.about")}
          >
            {t("title.about")}
          </Link>
        </li>
      </ul>
    </nav>
  );
}
export default NavMenu;
