import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";

function Referencias() {
  const { register, watch } = useForm();

  const name = watch("author_name");
  const date = watch("date");
  const recuperado = watch("recuperado");

  function obtenerInicial(name) {
    if (!name) return ""; // Verifica si el nombre está vacío o indefinido
    return name.trim().charAt(0).toUpperCase(); // Elimina espacios y convierte la inicial a mayúscula
  }

  //   const nombre = "Daniel";
  const inicial = obtenerInicial(name);
  //   console.log(inicial); // D

  // Convertir fecha y recuperado a formato de fecha si existen
  const formattedYear = date
    ? new Date(date).toLocaleDateString("es", {
        year: "numeric",
      })
    : "s.f."; // s.f. para sin fecha si no se ha proporcionado

  // Convertir fecha y recuperado a formato de fecha si existen
  const formattedDate = date
    ? new Date(date).toLocaleDateString("es", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      })
    : "s.f."; // s.f. para sin fecha si no se ha proporcionado

  const formattedRecuperado = recuperado
    ? new Date(recuperado).toLocaleDateString("es", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      })
    : "";

  return (
    <>
      <Helmet>
        <title>Referencias - Daniel Vargas</title>
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="">
            <b>Cita</b>
            <p>
              {"("}
              {watch("author_lastName")}
              {", "}
              {inicial}
              {". "}
              {formattedYear}
              {")"}
            </p>
            <hr />
            <b>Referencia bibliográfica para libro en línea</b>
            <p>
              {watch("author_lastName")}
              {", "}
              {inicial}
              {". ("}
              {formattedYear}
              {"). "}
              <i>{watch("title")}</i>
              {". "}
              {watch("website")}
              {". "}
              <a
                href={watch("link")}
                target="_blank"
                rel="noopener noreferrer"
                className="link-text"
              >
                {watch("link")}
              </a>
            </p>
            <hr />
            <b>Referencia bibliográfica para sitios web estáticos</b>
            <p>
              {watch("author_lastName")}
              {", "}
              {inicial}
              {". ("}
              {formattedDate}
              {"). "}
              <i>{watch("title")}</i>
              {". "}
              {watch("website")}
              {". "}
              <a
                href={watch("link")}
                target="_blank"
                rel="noopener noreferrer"
                className="link-text"
              >
                {watch("link")}
              </a>
            </p>
            <hr />
            <b>
              Referencia bibliográfica para sitios web con actualización
              frecuentes
            </b>
            <p>
              {watch("author_lastName")}
              {", "}
              {inicial}
              {". ("}
              {formattedDate}
              {"). "}
              <i>{watch("title")}</i>
              {". "}
              {watch("website")}
              {". Recuperado el "}
              {formattedRecuperado}
              {". "}
              <a
                href={watch("link")}
                target="_blank"
                rel="noopener noreferrer"
                className="link-text"
              >
                {watch("link")}
              </a>
            </p>
            <hr />
            <br />
          </div>
          <form className="form">
            <div className="block">
              <label>Inicial del autor: </label>
            </div>
            <div className="block">
              <input type="text" placeholder="D" {...register("author_name")} />
            </div>
            <div className="block">
              <label>Apellido del autor: </label>
            </div>
            <div className="block">
              <input
                type="text"
                placeholder="Vargas"
                {...register("author_lastName")}
              />
            </div>
            <div className="block">
              <label>Fecha del artículo: </label>
            </div>
            <div className="block">
              <input
                type="date"
                placeholder="01 de agosto de 2001"
                {...register("date")}
              />
            </div>
            <div className="block">
              <label>Título del artículo: </label>
            </div>
            <div className="block">
              <input
                type="text"
                placeholder="Título del artículo"
                {...register("title")}
              />
            </div>
            <div className="block">
              <label>Nombre del sitio web: </label>
            </div>
            <div className="block">
              <input
                type="text"
                placeholder="Nombre del sitio web"
                {...register("website")}
              />
            </div>
            <div className="block">
              <label>Recuperado: </label>
            </div>
            <div className="block">
              <input
                type="date"
                placeholder="Fecha del día que consulto el artículo"
                {...register("recuperado")}
              />
            </div>
            <div className="block">
              <label>Enlace del artículo: </label>
            </div>
            <div className="block">
              <input
                type="text"
                placeholder="https://blog.danielvargas.dev/article/path"
                {...register("link")}
              />
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
export default Referencias;
