import { faMoon, faSun } from "@fortawesome/free-regular-svg-icons";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";

function NavMenu(props) {
  const { t } = useTranslation();

  // const Navigate = useNavigate();

  // const actionLink = () => {
  //   Navigate("/contratar");
  // };

  return (
    <nav className="nav-icons">
      <ul className="nav-list">
        <li>
          <button
            type="button"
            className="button action"
            aria-label="Crear un pedido personalizado"
            // onClick={actionLink}
            onClick={() =>
              window.open(
                "https://mail.google.com/mail/?fs=1&tf=cm&source=mailto&to=hello@danielvargas.dev",
                "_blank"
              )
            }
          >
            Contratar
          </button>
        </li>
        <li hidden>
          <select className="button border icon" title="Cambiar Idioma">
            <option value="Español">ES</option>
            <option value="English">EN</option>
          </select>
        </li>
        <li>
          <button
            type="button"
            className="button border icon"
            onClick={props.handleMode}
            title="Cambiar Modo"
          >
            <FontAwesomeIcon icon={!props.dark ? faMoon : faSun} size="lg" />
          </button>
        </li>
        <li>
          <button
            type="button"
            className="button border icon"
            onClick={props.handleMenu}
            title="Abrir Menú de Navegación"
            aria-label={
              !props.menu ? t("button.menu.open") : t("button.menu.close")
            }
          >
            <FontAwesomeIcon icon={!props.menu ? faBars : faClose} size="lg" />
          </button>
        </li>
      </ul>
    </nav>
  );
}
export default NavMenu;
