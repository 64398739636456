import { Helmet } from "react-helmet";
import { websiteName } from "../../config/webConfig";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

function CustomProject() {
  const { t } = useTranslation();

  const { register, handleSubmit, watch, setValue } = useForm();

  const tipoProyecto = watch("tipoProyecto");

  useEffect(() => {
    if (tipoProyecto === "landingPage") {
      setValue("hosting", "1GB");
    }
  }, [tipoProyecto, setValue]);

  const onSubmit = (data) => {
    // Manejar el envío de datos
    console.log(data);
  };

  return (
    <>
      <Helmet>
        <title>
          {t("title.custom")} - {websiteName}
        </title>
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="title">
            <h1>{t("title.custom")}</h1>
          </div>
          <div className="content">
            <form onSubmit={handleSubmit(onSubmit)} className="form">
              {/* Información del Cliente */}
              <div className="form-block">
                <b>Información del Cliente:</b>
              </div>
              <div className="form-block">
                <input
                  type="text"
                  placeholder="Nombre"
                  {...register("firstName", { required: true })}
                />
                <input
                  type="text"
                  placeholder="Apellido"
                  {...register("lastName", { required: true })}
                />
              </div>
              <div className="form-block">
                <input
                  type="text"
                  placeholder="Correo Electrónico"
                  {...register("email", { required: true })}
                />
              </div>
              <div className="form-block">
                <input type="tel" placeholder="Teléfono" />
              </div>
              <div className="form-block">
                <input type="text" placeholder="Empresa (Opcional)" />
              </div>
              {/* Tipo de Proyecto */}
              <div className="form-block">
                <b>Tipo de Proyecto:</b>
              </div>
              <div className="form-block">
                <select {...register("website", { require: true })}>
                  <option value="">Selecciona el Tipo de Sitio Web</option>
                  <option value="Página de Aterrizaje">
                    Página de Aterrizaje
                  </option>
                  <option value="Sitio Web Básico">Sitio Web Básico</option>
                  <option value="Sitio Web Estándar">Sitio Web Estándar</option>
                  <option value="Sitio Web Profesional">
                    Sitio Web Profesional
                  </option>
                  <option value="Comercio Electrónico">
                    Comercio Electrónico
                  </option>
                </select>
                <FontAwesomeIcon icon={faAngleDown} size="sm" />
              </div>
              {/* Plataforma */}
              <div className="form-block">
                <b>Plataforma de Desarrollo:</b>
              </div>
              <div className="form-block">
                <select {...register("hosting", { required: true })}>
                  <option value="">Selecciona la Plataforma</option>
                  <option value="React">
                    React {"(Con Hosting adicional)"}
                  </option>
                  <option value="Google Sites">Google Sites</option>
                  <option value="Canva">Canva</option>
                  <option value="WordPress">WordPress</option>
                  <option value="Wix">Wix</option>
                  <option value="GoDaddy">GoDaddy</option>
                  <option value="Shopify">Shopify</option>
                </select>
                <FontAwesomeIcon icon={faAngleDown} size="sm" />
              </div>
              {/* Caracteristicas Adicionales */}
              <fieldset>
                <legend>Características Adicionales</legend>
                <label htmlFor="">
                  <input type="checkbox" />
                  Formulario de Contacto
                </label>
                <label htmlFor="">
                  <input type="checkbox" />
                  Formulario de Boletín
                </label>
                <label htmlFor="">
                  <input type="checkbox" />
                  Integración con Redes Sociales
                </label>
                <label htmlFor="">
                  <input type="checkbox" />
                  Funcionalidad de Blog
                </label>
                <label htmlFor="">
                  <input type="checkbox" />
                  Galería de imágenes o portafolio
                </label>
                <label htmlFor="">
                  <input type="checkbox" />
                  Reservas o Citas en Línea
                </label>
                <label htmlFor="">
                  <input type="checkbox" />
                  Múltiples Idiomas {"(React, WordPress, Shopify)"}
                </label>
                <label htmlFor="">
                  <input type="checkbox" />
                  Modo Oscuro {"(React, WordPress, Shopify)"}
                </label>
              </fieldset>
              {/* Hosting */}
              <div className="form-block">
                <b>Servicio de Hosting:</b>
              </div>
              <div className="form-block column">
                <label htmlFor="">
                  ¿Requiere servicio de hosting para su sitio web?
                  <select>
                    <option value="">Selecciona una opción</option>
                    <option value="">Sí</option>
                    <option value="">No</option>
                  </select>
                </label>
                <label htmlFor="">
                  Duración del servicio de hosting:
                  <select>
                    <option value="">Selecciona una opción</option>
                    <option value="">Mensual</option>
                    <option value="">Anual</option>
                  </select>
                </label>
              </div>
              {/* Presupuesto */}
              <div className="block">
                <label htmlFor="">
                  Presupuesto Aproximado:
                  <input type="number" placeholder="Ej. 500 USD" />
                </label>
              </div>
              {/* Tiempo de Entrega */}
              <div className="block">
                <label htmlFor="">
                  Plazo Deseado para la Entrega:
                  <input type="date" />
                </label>
              </div>
              {/* Mensaje adicional */}
              <label>
                Mensaje Adicional o Requerimientos Especiales:
                <textarea
                  name="additionalMessage"
                  placeholder="Escribe cualquier requerimiento adicional..."
                />
              </label>
              <button
                type="submit"
                className="button border ghost"
                aria-label="Enviar pedido personalisado"
              >
                Enviar Pedido Personalisado
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
export default CustomProject;
