import { Helmet } from "react-helmet";
import { useState } from "react";
import { db } from "../../firebase/config";
import { websiteName } from "../../config/webConfig";
import { useProjects } from "../../firebase/fbQueries";
import ProjectBoxLoad from "../common/ProjectBoxLoad";
import ProjectBox from "../common/ProjectBox";

function Projects() {
  const projects = useProjects(db);
  const [selectedCategory, setSelectedCategory] = useState("Todos");
  const categories = [
    "Todos",
    "Página de Aterrizaje",
    "Sitio Web Básico",
    "Sitio Web Estándar",
    "Sitio Web Profesional",
    "Aplicación Web",
    "Comercio Electrónico"
  ];

  const projectsLoad = [
    <ProjectBoxLoad />,
    <ProjectBoxLoad />,
    <ProjectBoxLoad />,
    <ProjectBoxLoad />,
    <ProjectBoxLoad />,
    <ProjectBoxLoad />,
    <ProjectBoxLoad />,
    <ProjectBoxLoad />,
  ];

  // Función para manejar el filtro de proyectos
  const filteredProjects = projects.filter((project) => {
    return (
      selectedCategory === "Todos" || project.label === selectedCategory
    );
  });

  return (
    <>
      <Helmet>
        <title>Proyectos - {websiteName}</title>
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="row-caption">
            <h1>Proyectos</h1>
            <span className="line"></span>
            <p>
              Explora los proyectos en los que he trabajado y conoce mis
              resultados.
            </p>
          </div>
          <div className="row-buttons">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`button border ${selectedCategory === category ? "active" : ""}`}
              >
                {category}
              </button>
            ))}
          </div>
          <div className="grid">
            {projects.length === 0 ? (
              <>{projectsLoad}</>
            ) : (
              filteredProjects
                .filter((project) => project.show)
                .sort((a, b) => a.label - b.label)
                .map((project, index) => (
                  <ProjectBox key={index} {...project} />
                ))
            )}
          </div>
        </div>
      </section>
    </>
  );
}
export default Projects;
